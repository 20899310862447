<template>
  <div>
    <div class="tips">
      <div class="tip-text"><img src="@/assets/image/apply/icon_tips.png"/> 我们已向您的对公账户转账，请填写收到的转账金额。
        （请联系您的银行对公账户管理员，通过银行柜台、网上银行、手机银行等方式获取，并登录和上金融后输入打款金额完成认证流程。)
      </div>
    </div>
    <div class="verify-content">
      <van-field label="账户名称"
                 v-model="acctName"
                 center
                 required
                 disabled="disabled"
                 placeholder="请输入账户名称"
                 :border="true"/>
      <van-field label="银行账号"
                 v-model="acctPubNo"
                 center
                 required
                 disabled="disabled"
                 type="tel"
                 placeholder="请输入银行账号"
                 :border="true"/>
      <van-field v-model="amount"
                 center
                 required
                 clearable
                 type="number"
                 label="转账金额"
                 placeholder="请输入转账金额"
                 :border="false"
                 use-button-slot>
        <van-button slot="button"
                    size="small"
                    type="plain"
                    class="sms-button">元
        </van-button>


      </van-field>
    </div>
    <div class="split-line"></div>
    <button class="confirm-button" @click="confirmVerify">确&emsp;定</button>
  </div>
</template>

<script>
export default {
  name: "moneyverify",
  data() {
    return {
      time: '获取验证码',
      acctName: '',
      acctPubNo: "",
      amount: '',
      isClick: true,
    }
  },
  methods: {
    getDraftInfo() {
      let request_uri = '/frontend/merchant/getMerchantDraft'
      let params = {
        draftId: this.draftId,
      }

      let _this = this;
      Http.post(request_uri, params).then(result => {
        if (result.code === 200) {
          _this.acctName = result.data.acctName
          _this.acctPubNo = result.data.acctNo
        }
      }).catch(error => {

      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    confirmVerify() {
      let _this = this
      let params = {
        "amount": this.amount,
        "code": "",
        "draftId": this.draftId,
      }
      Http.post('/frontend/merchant/verifyMessageCodeAndRandomAmount', params).then(result => {
        if (result.code === 200) {
          _this.$message.success("验证成功！")
          _this.$router.push({path: "/h5/applying"})
        } else {
          _this.$message.error(result.message || "验证失败，请重试！")
        }
      }).catch(error => {
        // console.log(error)
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
  },
  mounted() {
    this.draftId = localStorage.getItem("draftId")
    this.getDraftInfo()
  },
  created() {
    document.title = "打款验证"
  },
}
</script>

<style scoped>
.tips {
  width: 92vw;
  margin-top: 20px;
  margin-left: 4vw;
  height: 140px;
  background: #FFFCF2;
  border: 1px solid rgba(166, 132, 31, 0.28);
  border-radius: 8px;
}

.tip-text {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 20px;
  line-height: 24px;
  font-size: 14px;
}

.tips img {
  width: 20px;
  height: 20px;
  /*margin-top: 20px;*/
  /*margin-left: 32px;*/
}

.verify-content {
  height: 220px;
  padding-top: 23px;
}

.sms-button {
  border: 1px solid #F6F6F6;
}

.split-line {
  width: 92vw;
  margin-left: 4vw;
  height: 1px;
  background: #9A9A9A;
  opacity: 0.4;
  margin-bottom: 40px;

}

.confirm-button {
  width: 61.2vw;
  margin-left: 19.4vw;
  margin-top: 240px;
  color: #FFFFFF;
  height: 40px;
  background: #E9BE3E;
  border: 1px solid #E9BE3E;
  border-radius: 20px;
}

/deep/ .van-field__control:disabled {
  color: #323233;
  -webkit-text-fill-color: #323233;
}

/deep/ .van-field--disabled .van-field__label {
  color: #323233;
}
</style>